
import { defineComponent } from 'vue';
import { AppWrapper } from '@/components';
import { DmTile } from '@dobrymechanik/vue-ui';
import { versions } from './_versions';
import Markdown from 'vue3-markdown-it';

export default defineComponent({
  name: 'Changelog',
  components: {
    AppWrapper,
    DmTile,
    Markdown
  },
  data () {
    return {
      versions: versions.reverse()
    };
  }
});
