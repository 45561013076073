import pl from './_pl.md';
import en from './_en.md';
import { ChangelogVersion } from '../../models';

const version: ChangelogVersion = {
  name: '1.0.0',
  date: '',
  description: {
    pl,
    en
  }
};

export default version;
