import pl from './_pl.md';
import en from './_en.md';
import { ChangelogVersion } from '../../models';

const version: ChangelogVersion = {
  name: '2.6.0',
  date: '2021-06-16',
  description: {
    pl,
    en
  }
};

export default version;
