import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")
  const _component_DmTile = _resolveComponent("DmTile")
  const _component_AppWrapper = _resolveComponent("AppWrapper")

  return (_openBlock(), _createBlock(_component_AppWrapper, {
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: _ctx.$bem({ e: 'content'})
      }, [
        _createVNode("h1", {
          class: _ctx.$bem({ e: 'title'})
        }, _toDisplayString(_ctx.$t('changelog.title')), 3),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.versions, (version) => {
          return (_openBlock(), _createBlock(_component_DmTile, {
            key: version.name,
            color: "white",
            bordered: "",
            rounded: "",
            class: _ctx.$bem({ e: 'version'})
          }, {
            default: _withCtx(() => [
              _createVNode("div", {
                class: _ctx.$bem({ e: 'version-header'})
              }, [
                _createVNode("h2", {
                  class: _ctx.$bem({ e: 'version-name'})
                }, " v" + _toDisplayString(version.name), 3),
                _createVNode("h3", {
                  class: _ctx.$bem({ e: 'version-date'})
                }, _toDisplayString(version.date), 3)
              ], 2),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'version-description'})
              }, [
                _createVNode(_component_Markdown, {
                  source: version.description[_ctx.$i18n.locale]
                }, null, 8, ["source"])
              ], 2)
            ]),
            _: 2
          }, 1032, ["class"]))
        }), 128))
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}