import v1r0 from './v1-0';
import v1r1 from './v1-1';
import v1r2 from './v1-2';
import v1r3 from './v1-3';
import v1r4 from './v1-4';
import v2r0 from './v2-0';
import v2r1 from './v2-1';
import v2r2 from './v2-2';
import v2r3 from './v2-3';
import v2r4 from './v2-4';
import v2r5 from './v2-5';
import v2r6 from './v2-6';
import v2r7 from './v2-7';

export const versions = [
  v1r0,
  v1r1,
  v1r2,
  v1r3,
  v1r4,
  v2r0,
  v2r1,
  v2r2,
  v2r3,
  v2r4,
  v2r5,
  v2r6,
  v2r7
];
